<template>
  <a-drawer
    class="patrol-detail-drawer"
    :body-style="{ padding: '8px 24px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="840"
    title="资源巡检项目明细"
    @close="
      () => {
        visible = false
        currentStatus = 'all'
        dataSource = []
        total = 0
        detail = undefined
      }
    "
  >
    <template v-if="detail">
      <a-descriptions style="margin-bottom: -20px" :column="2">
        <a-descriptions-item label="资源名称">{{
          detail.name
        }}</a-descriptions-item>
        <a-descriptions-item label="IP">{{
          detail.monitor_address
        }}</a-descriptions-item>
        <a-descriptions-item label="检查结果" :span="2">
          <a-radio-group v-model="currentStatus" @change="onChange">
            <a-radio value="all">全部</a-radio>
            <a-radio value="pass">正常</a-radio>
            <a-radio value="failed">异常</a-radio>
          </a-radio-group>
        </a-descriptions-item>
      </a-descriptions>

      <div id="tablePdfDom" style="padding-top: 24px;">
        <a-table
          class="custom-table"
          :rowClassName="() => 'pdf-split-flag'"
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          row-key="name"
          :scroll="{ x: scrollX }"
          :total="total"
          :loading="loading"
        >
          <template slot="advice" slot-scope="text">
            {{ text ? text : '-' }}
          </template>
          <template slot="value" slot-scope="text">
            {{ text ? text : '-' }}
          </template>
          <template slot="priority" slot-scope="text">
            <a-tag :color="getColor(text)">
              {{ $t(`patrol_item_priority.${text}`) }}
            </a-tag>
          </template>
          <template slot="pass" slot-scope="text">
            <span
              :style="{
                display: 'inline-block',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                marginRight: '8px',
                background: text ? '#5FC367' : '#FF4D4F'
              }"
            ></span
            >{{ text ? '正常' : '异常' }}
          </template>
          <template slot="recommendedValue" slot-scope="text, record">
            <span>{{ $t(`compare_condition_mark.${record.condition}`) }} {{text}}</span>
          </template>
        </a-table>
      </div>

      <a-button
        type="primary"
        icon="download"
        style="position: absolute; right: 24px; top: 72px"
        @click="exportPdf"
        :loading="exportLoading"
        >导出</a-button
      >
    </template>
  </a-drawer>
</template>

<script>
import PdfLoader from '@/utils/htmlToPdf'

export default {
  name: 'SourcePatrolDrawer',
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  data () {
    return {
      visible: false,
      exportLoading: false,
      dataSource: [],
      loading: false,
      total: 0,
      columns: [
        {
          dataIndex: 'priority',
          title: '危害程度',
          align: 'center',
          width: 90,
          scopedSlots: {
            customRender: 'priority'
          }
        },
        {
          dataIndex: 'name',
          title: '检查项名称',
          align: 'center',
          width: 160,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'pass',
          title: '巡检结果',
          align: 'center',
          width: 120,
          scopedSlots: {
            customRender: 'pass'
          }
        },
        {
          dataIndex: 'value',
          title: '监控项',
          align: 'center',
          width: 120,
          scopedSlots: {
            customRender: 'value'
          }
        },
        {
          dataIndex: 'recommended_value',
          title: '建议值',
          align: 'center',
          width: 120,
          scopedSlots: {
            customRender: 'recommendedValue'
          }
        },
        {
          dataIndex: 'advice',
          title: '建议',
          align: 'center',
          width: 180,
          scopedSlots: {
            customRender: 'advice'
          }
        }
      ],
      currentStatus: 'all',
      detail: undefined
    }
  },
  methods: {
    show (record) {
      this.visible = true
      this.detail = record
      this.dataSource = [...this.detail.pass, ...this.detail.failed]
    },
    getColor (v) {
      if (v === 'high') return 'red'
      if (v === 'medium') return 'orange'
      return 'blue'
    },
    exportPdf () {
      this.exportLoading = true
      const ele = document.getElementById('tablePdfDom')
      const pdf = new PdfLoader(ele, this.detail.name, 'pdf-split-flag')
      pdf.outPdf().then(() => {
        this.exportLoading = false
      })
    },
    onChange (v) {
      if (v.target.value === 'pass') this.dataSource = [...this.detail.pass]
      else if (v.target.value === 'failed') { this.dataSource = [...this.detail.failed] } else if (v.target.value === 'all') { this.dataSource = [...this.detail.pass, ...this.detail.failed] }
    }
  }
}
</script>

<style lang="less">
.patrol-detail-drawer {
  .ant-drawer-title {
    font-size: 18px;
    font-weight: 500;
    color: #096dd9;
    line-height: 25px;
  }
  .ant-descriptions-view {
    padding: 16px 8px;
    .ant-descriptions-item {
      font-size: 14px;
      line-height: 20px;
    }
    .ant-descriptions-item-label {
      color: rgba(0, 0, 0, 0.5);
    }
    .ant-descriptions-item-content {
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
</style>
