<template>
  <a-tag :color="color">{{ $t(`patrol_strategy_status.${status}`) }}</a-tag>
</template>

<script>

export default {
  name: 'PatrolStrategyTag',
  props: {
    status: {
      type: String,
      default: 'waiting'
    }
  },
  computed: {
    color () {
      switch (this.status) {
        case 'waiting':
          return 'blue'
        case 'running':
          return 'green'
        case 'finished':
          return 'red'
        default:
          return ''
      }
    }
  }
}
</script>
