<template>
  <div class="rete-module-container">
    <a-progress
      type="dashboard"
      :percent="percent"
      :width="85"
      :strokeWidth="6"
    >
      <template #format="percent">
        <div
          :style="{
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '33px',
            marginTop: '4px'
          }"
        >
          {{ percent }}%
        </div>
        <div
          style="
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.5);
          "
        >
          {{progressTitle}}
        </div>
      </template>
    </a-progress>

    <div class="statistic-data-container">
      <div
        v-for="(item, title) in statisticData"
        :key="item.title"
        style="margin: 0 4px 4px"
      >
        <div>
          <a-icon
            type="container"
            v-if="title == 'total'"
            theme="filled"
            style="color: #096dd9; font-size: 16px"
          />
          <a-icon
            type="check-circle"
            v-if="title === 'pass'"
            theme="filled"
            style="color: #5fc367; font-size: 16px"
          />
          <a-icon
            type="info-circle"
            v-if="title === 'failed'"
            theme="filled"
            style="color: #ff5b73; font-size: 16px"
          />
        </div>
        <p class="title">{{ item.title }}</p>
        <p class="value">{{ item.value }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RateModule',
  props: {
    statisticData: {
      type: Object
    },
    progressTitle: {
      type: String
    }
  },
  data () {
    return {
      percent: 0
    }
  },
  mounted () {
    if (Object.keys(this.statisticData).length) {
      if (
        this.statisticData.pass.value !== 0 &&
        this.statisticData.total.value !== 0
      ) {
        this.percent = parseInt(
          (this.statisticData.pass.value / this.statisticData.total.value) * 100
        )
      }
    }
  }
}
</script>

<style lang="less">
.rete-module-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .statistic-data-container {
    display: flex;
    margin-left: 8px;
    flex: 1;
    justify-content: space-around;
    p {
      margin: 0;
    }
    p.title {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 17px;
      min-width: 62px;
    }
    p.value {
      font-size: 24px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 33px;
    }
  }
}
</style>
