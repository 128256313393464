<template>
  <div class="patrol-strategy-report-container">
    <a-spin :spinning="spinning">
      <a-descriptions
        class="report-base-info"
        :column="{ xxl: 4, xl: 3, lg: 2, md: 2, xs: 1 }"
      >
        <a-descriptions-item label="巡检资源类型">
          {{ $t(`source_type.${detail.source_type}`) }}
        </a-descriptions-item>
        <a-descriptions-item label="巡检资源数量">
          {{ detail.sources.length }}
        </a-descriptions-item>
        <a-descriptions-item label="巡检方式">
          {{ detail.periodic ? '按周期' : '仅一次' }}
        </a-descriptions-item>
        <a-descriptions-item label="创建时间">
          {{ detail.created_at }}
        </a-descriptions-item>
        <a-descriptions-item label="最近执行时间">
          {{ detail.last_at }}
        </a-descriptions-item>
        <a-descriptions-item label="下次执行时间">{{ detail.next_at }}</a-descriptions-item>
        <a-descriptions-item label="巡检状态"
          ><patrol-strategy-tag :status="detail.status"></patrol-strategy-tag
        ></a-descriptions-item>
      </a-descriptions>

      <div class="patrol-strategy-content-container" id="pdfDom">
        <header>{{ detail.name }}</header>
        <div style="text-align: center;">
          <a-space>
          <p class="time" v-if="currentReportDate">
            巡检时间：{{ currentReportDate.value }}
          </p>
          <p class="time">
            资源类型：{{ $t(`source_type.${detail.source_type}`)}}
          </p>
        </a-space>
        </div>

        <div class="operation-container" v-show="!exportLoading">
          <template>
            <span
              style="
                font-size: 14px;
                color: rgba(0, 0, 0, 0.5);
                line-height: 20px;
              "
            >
              选择历史报告：
            </span>
            <simple-select
              :options="historyReportOptions"
              :width="170"
              v-model="currentReportDate"
            ></simple-select>
          </template>
          <a-button
            type="primary"
            icon="download"
            style="float: right; margin-left: 24px"
            @click="exportPdf"
            :loading="exportLoading"
          >
            导出
          </a-button>
        </div>

        <a-row :gutter="40" class="info-overview" v-if="currentReportDetail">
          <a-col
            :md="24"
            :xl="12"
            style="margin-bottom: 16px"
            class="pdf-split-flag"
          >
            <div class="small-module-title">
              <a-icon type="profile"></a-icon>
              <span> 巡检概况</span>
            </div>

            <a-row :gutter="16" style="padding-left: 12px; margin-bottom: 8px">
              <a-col :span="12">
                <div style="border-right: 1px solid #ededed; padding-bottom: 8px">
                  <div class="title">巡检合格率</div>
                  <rate-module :statistic-data="overviewRuleRate" progressTitle ="合格率"></rate-module>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="title">巡检异常情况</div>
                <pie-chart
                  v-if="priorityData.length"
                  :chart-data="priorityData"
                  chart-id="patrol-failed-chart"
                  :height="100"
                  :flip-page="false"
                  legendPosition="right"
                  :size="12"
                  :colors="['#FF5B73', '#F7B500', '#096DD9']"
                  :legendOffsetX="-32"
                  style="margin-top: -10px"
                ></pie-chart>
              </a-col>
            </a-row>
          </a-col>

          <a-col
            :md="24"
            :xl="12"
            style="margin-bottom: 16px"
            class="pdf-split-flag"
          >
            <div class="small-module-title">
              <a-icon type="profile"></a-icon>
              <span> 资源概况</span>
            </div>

            <a-row :gutter="16" style="padding-left: 12px; margin-bottom: 8px">
              <a-col :span="12">
                <div style="border-right: 1px solid #ededed; padding-bottom: 8px">
                  <div class="title">资源成功率</div>
                  <rate-module :statistic-data="overviewSourceRate" progressTitle ="成功率"></rate-module>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="title">资源异常情况</div>
                <pie-chart
                  :chart-data="[
                    { name: '资源成功数', value: overviewSourceRate.pass.value },
                    { name: '资源失败数', value: overviewSourceRate.failed.value }
                  ]"
                  chart-id="source-failed-chart"
                  :height="100"
                  :flip-page="false"
                  legendPosition="right"
                  :size="12"
                  :colors="['#5FC367', '#FF5B73']"
                  :legendOffsetX="-32"
                  style="margin-top: -10px"
                ></pie-chart>
              </a-col>
            </a-row>
          </a-col>

          <a-col :span="24" style="margin-bottom: 16px">
            <div class="small-module-title">
              <a-icon type="profile"></a-icon>
              <span> 资源巡检明细</span>
            </div>

            <a-tabs v-model="activeKey" type="card" style="margin-top: 32px" @change="handleChange">
              <a-tab-pane
                key="total"
                :tab="`全部 (${
                  currentReportDetail.statistic.pass.length +
                  currentReportDetail.statistic.failed.length
                })`"
              ></a-tab-pane>
              <a-tab-pane
                key="pass"
                :tab="`巡检成功资源 (${currentReportDetail.statistic.pass.length})`"
              ></a-tab-pane>
              <a-tab-pane
                key="failed"
                :tab="`巡检失败资源 (${currentReportDetail.statistic.failed.length})`"
              ></a-tab-pane>
            </a-tabs>

            <a-table
              class="custom-table"
              :columns="columns"
              :pagination="false"
              :scroll="{ x: scrollX }"
              :data-source="dataSource"
              style="margin-top: 8px"
              rowKey="monitor_address"
              :rowClassName="() => 'pdf-split-flag'"
            >
              <template slot="name" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="text">
                  <a @click="$refs.sourcePatrolDrawer.show(record)">
                    {{ text ? text : '-' }}
                  </a>
                </a-tooltip>
              </template>
              <template slot="status" slot-scope="text">
                <a-icon
                  type="check-circle"
                  style="color: #52c41a"
                  theme="filled"
                  v-if="text"
                ></a-icon>
                <a-icon
                  type="close-circle"
                  style="color: #52c41a"
                  theme="filled"
                  v-else
                ></a-icon>
                {{ text }}
              </template>
              <template slot="items" slot-scope="text, record">
                {{ record.failed.length + record.pass.length }}
              </template>
              <template slot="rate" slot-scope="text, record">
                {{ record | getPassRate }}%
              </template>
              <template slot="abnormal" slot-scope="text, record">
                <a-space size="middle">
                  <span
                    style="
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    border: 1px solid #ffccc7;
                    background: #fff1f0;
                    color: #ff4d4f;
                    text-align: center;
                    line-height: 22px;
                    border-radius: 4px;
                  "
                  >
                    {{ record.priority.high }}
                  </span>
                  <span
                    style="
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    border: 1px solid #fff1b8;
                    background: #fffbe6;
                    color: #faad14;
                    text-align: center;
                    line-height: 22px;
                    border-radius: 4px;
                  "
                  >

                    {{ record.priority.medium }}
                  </span>
                  <span
                    style="
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    border: 1px solid #bae0ff;
                    background: #e6f4ff;
                    color: #096dd9;
                    text-align: center;
                    line-height: 22px;
                    border-radius: 4px;
                  "
                  >
                    {{ record.priority.low }}
                  </span>
                </a-space>
              </template>
            </a-table>
          </a-col>
        </a-row>
      </div>
      <source-patrol-drawer ref="sourcePatrolDrawer"></source-patrol-drawer>
    </a-spin>
  </div>
</template>

<script>
import {
  getPatrolStrategy,
  getPatrolStrategyReport
} from '@/api/patrol-strategy.js'
import PatrolStrategyTag from '@/components/tag/PatrolStrategyTag'
import RateModule from './modules/RateModule.vue'
import SimpleSelect from '@/components/select/SimpleSelect'
import SourcePatrolDrawer from '@/components/drawer/SourcePatrolDrawer'
import PieChart from '@/components/chart/PieChart'
import PdfLoader from '@/utils/htmlToPdf'

export default {
  name: 'PatrolStrategyReport',
  components: {
    PatrolStrategyTag,
    RateModule,
    SimpleSelect,
    SourcePatrolDrawer,
    PieChart
  },
  data () {
    return {
      spinning: false,
      id: this.$route.params.id,
      detail: {
        name: '',
        source_type: '',
        sources: [],
        periodic: false,
        run_at: null,
        cron: null,
        last_at: '',
        next_at: '',
        created_at: '',
        updated_at: ''
      },
      columns: [
        {
          dataIndex: 'name',
          title: '资源名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'monitor_address',
          title: 'IP 地址',
          width: 160
        },
        {
          dataIndex: 'items',
          title: '检查项',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'items'
          }
        },
        {
          dataIndex: 'rate',
          title: '合格率',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'rate'
          }
        },
        {
          dataIndex: 'abnormal',
          title: '异常分布',
          width: 200,
          scopedSlots: {
            customRender: 'abnormal'
          }
        }
      ],
      currentReportDate: undefined,
      historyReportOptions: [],
      reportDetailList: [],
      currentReportDetail: undefined,
      overviewRuleRate: {},
      overviewSourceRate: {},
      dataSource: [],
      passData: [],
      failedData: [],
      priorityData: [],
      exportLoading: false,
      activeKey: 'total'
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.spinning = true
      const p1 = getPatrolStrategy(this.id).then(res => {
        this.detail = res.data
      })
      const p2 = getPatrolStrategyReport(this.id).then(res => {
        this.reportDetailList = res.data.data
        if (this.reportDetailList.length) {
          this.reportDetailList.forEach(item => {
            this.historyReportOptions.push({
              label: item.name,
              value: item.name
            })
          })
          this.currentReportDate = this.historyReportOptions[0]
        }
      })
      Promise.all([p1, p2]).finally(() => { this.spinning = false })
    },
    handleChange (v) {
      if (v === 'total') {
        this.dataSource = [...this.passData, ...this.failedData]
      } else if (v === 'pass') this.dataSource = this.passData
      else this.dataSource = this.failedData
    },
    exportPdf () {
      this.exportLoading = true
      this.$nextTick(() => {
        const ele = document.getElementById('pdfDom')
        const pdf = new PdfLoader(ele, this.detail.name, 'pdf-split-flag')
        pdf.outPdf().then(() => {
          this.exportLoading = false
        })
      })
    }
  },
  filters: {
    getPassRate (record) {
      if (record.pass.length !== 0) {
        return (
          parseInt(
            (record.pass.length / (record.pass.length + record.failed.length)) *
              10000
          ) / 100
        )
      }
      return 0
    }
  },
  watch: {
    currentReportDate (v) {
      if (v.value !== undefined) {
        this.activeKey = 'total'
        this.currentReportDetail = this.reportDetailList.find(
          item => item.name === v.value
        )
        if (this.currentReportDetail) {
          this.overviewRuleRate = {
            total: {
              title: '总巡检项',
              value:
                this.currentReportDetail.statistic.overview.rule.failed +
                this.currentReportDetail.statistic.overview.rule.pass
            },
            pass: {
              title: '巡检正常项',
              value: this.currentReportDetail.statistic.overview.rule.pass
            },
            failed: {
              title: '巡检异常项',
              value: this.currentReportDetail.statistic.overview.rule.failed
            }
          }
          this.overviewSourceRate = {
            total: {
              title: '巡检资源数',
              value:
                this.currentReportDetail.statistic.overview.source.failed +
                this.currentReportDetail.statistic.overview.source.pass
            },
            pass: {
              title: '巡检成功数',
              value: this.currentReportDetail.statistic.overview.source.pass
            },
            failed: {
              title: '巡检失败数',
              value: this.currentReportDetail.statistic.overview.source.failed
            }
          }
          this.passData = this.currentReportDetail.statistic.pass
          this.failedData = this.currentReportDetail.statistic.failed
          this.dataSource = [...this.passData, ...this.failedData]
          this.priorityData = [
            {
              value: this.currentReportDetail.statistic.overview.priority.high,
              name: '危险程度高'
            },
            {
              value:
                this.currentReportDetail.statistic.overview.priority.medium,
              name: '危险程度中'
            },
            {
              value: this.currentReportDetail.statistic.overview.priority.low,
              name: '危险程度低'
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="less">
.patrol-strategy-report-container {
  background: #fff;
  padding: 40px 28px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  position: relative;

  .report-base-info {
    .ant-descriptions-title {
      font-size: 22px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 30px;
    }
    .ant-descriptions-view {
      background: #fafafa;
      border-radius: 8px;
      padding: 24px 56px 8px;
      .ant-descriptions-item {
        font-size: 16px;
        line-height: 22px;
      }
      .ant-descriptions-item-label {
        color: rgba(0, 0, 0, 0.5);
      }
      .ant-descriptions-item-content {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .patrol-strategy-content-container {
    margin-top: 40px;
    position: relative;
    padding-top: 24px;

    .small-module-title {
      height: 35px;
      background: #e4f1ff;
      border-radius: 8px;
      color: #096dd9;
      padding: 0 16px;
      margin-bottom: 16px;

      i {
        font-size: 16px;
        height: 20px;
        vertical-align: sub;
      }
      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 37px;
        vertical-align: middle;
      }
    }

    & > header {
      font-size: 30px;
      font-weight: 500;
      color: #096dd9;
      line-height: 42px;
      text-align: center;
    }
    .time {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 20px;
      margin: 16px 0 56px;
      text-align: center;
    }
    .operation-container {
      position: absolute;
      right: 0px;
      top: 100px;
    }
    .info-overview {
      div.title {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
        margin: 16px 0 20px;
      }
    }
  }
}
</style>
