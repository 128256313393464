import request from '@/utils/request'

const urlPrefix = '/patrol-strategies'

export function getPatrolStrategy (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function getPatrolStrategyReport (id) {
  return request.get(`${urlPrefix}/${id}/reports`)
}

export function createPatrolStrategy (data) {
  return request.post(urlPrefix, data)
}

export function getPatrolStrategyList (params) {
  return request.get(urlPrefix, { params })
}

export function updatePatrolStrategy (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deletePatrolStrategy (id) {
  return request.delete(`${urlPrefix}/${id}`)
}
